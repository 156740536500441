<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" md="6" align-self="end">
        <v-btn 
          color="primary"
          class="text-white mb-md-6"
          :block="$vuetify.breakpoint.xs"
          :disabled="adminProfile.role_id == 3"
          @click="createOrderDialog()"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add new order
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-form>
          <v-text-field
            v-model="query"
            @input="fetchOrders()"
            outlined
            dense
            hint="Search with order ID, name or phone number"
            persistent-hint
            color="primary"
            class="mb-4"
            hide-details="auto"
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-combobox
                v-model="status"
                small-chips
                deletable-chips
                outlined
                dense
                hide-details="auto"
                color="primary"
                label="Order Status"
                :items="Object.keys(statuses)"
                @input="fetchOrders()"
                class="mb-4"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                v-model="dateRangeDialog"
                :close-on-content-click="false"
                :offset-y="$vuetify.breakpoint.xs"
                :offset-x="$vuetify.breakpoint.smAndUp"
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date range"
                    append-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details="auto"
                    dense
                    outlined
                    color="primary"
                    class="mb-4"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  range
                  v-model="dateRange"
                  color="secondary"
                  header-color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="clearDate"
                  >
                    Clear
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="updateDate"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :headers="getHeaders"
            :items="orders"
            disable-pagination
            disable-sorting
            disable-filtering
            hide-default-footer
            v-if="$vuetify.breakpoint.smAndDown"
            show-expand
            class="text-caption mobile-table rounded"
          >
            <template v-slot:item.order_status="{ item }">
              <v-chip
                :color="getColor(item.order_status.name)"
                class="justify-center chips white--text px-1"
                label
                small
              >
                {{ item.order_status.name }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-4 px-2">
                <p class="mb-1"><strong>Customer:</strong> {{ item.customer_name }} ({{ item.customer_number }})</p>
                <p v-if="item.delivery_address != ''" class="mb-1"><strong>Address:</strong> {{ item.delivery_address }}</p>
                <p v-if="item.booking" class="mb-1">
                  <strong>Delivery date:</strong> 
                  {{ new Date(item.booking.slot.slot_date).toLocaleDateString() + ` (${item.booking.slot.slot_time})` }}
                </p>
                <p class="mb-1"><strong>Delivery method:</strong> {{ item.delivery_method }}</p>
                <p class="mb-1"><strong>Payment method:</strong> {{ item.payment_method }}</p>
                <p v-if="notesExist(item)" class="mb-1"><strong>Notes:</strong> {{ item.notes }}</p>

                <div class="d-flex align-center mt-5">
                  <v-btn
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(item.id)"
                  >
                    <span v-if="item.order_status.name==='new' && adminProfile.role_id != 3">Edit</span>
                    <span v-else>View</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <template v-if="item.order_status.name==='new' && adminProfile.role_id != 3">
                    <v-btn
                      depressed
                      color="success"
                      class="white--text mr-2"
                      small
                      @click="confirmApproveOrder(item)"
                    >
                      Approve
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="white--text"
                      small
                      @click="confirmRejectOrder(item)"
                    >
                      Reject
                    </v-btn>
                  </template>
                </div>
              </td>
            </template>
          </v-data-table>
          <v-simple-table class="text-body-2 rounded" v-if="$vuetify.breakpoint.mdAndUp">
            <thead class="primary">
              <tr>
                <th class="text-left white--text">
                  Status
                </th>
                <th class="text-left white--text">
                  Order ID
                </th>
                <th class="text-left white--text">
                  Name
                </th>
                <th v-if="$vuetify.breakpoint.mdAndUp" class="text-left white--text">
                  Phone No.
                </th>
                <th v-if="$vuetify.breakpoint.lgAndUp" class="text-left white--text">
                  Address
                </th>
                <th class="text-center white--text col-width">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="order in orders"
                :key="order.id"
              >
                <td>
                  <v-chip
                    :color="getColor(order.order_status.name)"
                    class="justify-center chips white--text"
                    small
                    label
                  >
                    {{ order.order_status.name }}
                  </v-chip>
                </td>
                <td>{{ order.order_number }}</td>
                <td>{{ order.customer_name }}</td>
                <td v-if="$vuetify.breakpoint.mdAndUp">{{ order.customer_number }}</td>
                <td v-if="$vuetify.breakpoint.lgAndUp">{{ order.delivery_address }}</td>
                <td class="text-right">
                  <template v-if="order.order_status.name==='new' && adminProfile.role_id != 3" class="">
                    <v-btn
                      depressed
                      color="success"
                      class="white--text mr-2"
                      small
                      @click="confirmApproveOrder(order)"
                    >
                      Approve
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="white--text mr-2"
                      small
                      @click="confirmRejectOrder(order)"
                    >
                      Reject
                    </v-btn>
                  </template>
                  <v-btn 
                    depressed
                    color="blue"
                    class="white--text"
                    small
                    @click="updateOrderDialog(order.id)"
                  >
                    <span v-if="order.order_status.name==='new' && adminProfile.role_id != 3">Edit</span>
                    <span v-else>View</span>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-dialog
          v-model="orderDialog"
          :width="$vuetify.breakpoint.xs?'90%':'60%'"
          persistent
        >
          <v-card>
            <v-container class="pa-0">
              <v-card-text>
                <v-form v-model="valid" ref="orderForm" @submit.prevent="onSubmit(editedItem.id)">
                  <v-row>
                    <v-col cols="12" md="6" class="pr-md-5">
                      <v-autocomplete 
                        outlined 
                        dense
                        @change="addToCart(selectedItem.id)"
                        label="Search menu item"
                        :items="menus"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="selectedItem"
                        :disabled="!allowEditing"
                      >
                      </v-autocomplete>
                      <v-divider class="mb-1"></v-divider>
                      <p class="text-left">Order Summary</p>
                      <p v-if="cartItems.length === 0" class="text-center">No items selected yet.</p>
                      <div 
                        class="d-flex justify-center align-center"
                        v-for="item in cartItems" 
                        :key="item.name"
                      >
                        {{ item.name }}
                        <v-spacer></v-spacer>
                        <v-btn 
                          color="primary"
                          @click="item.qty===1?removeFromCart(item.id):updateCart(item, item.qty--)"
                          icon
                          :disabled="!allowEditing"
                        >
                          <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                        <span>{{ item.qty }}</span>
                        <v-btn 
                          color="primary"
                          @click="updateCart(item, item.qty++)"
                          icon
                          :disabled="!allowEditing"
                        >
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                      <p class="text-right mt-2 mb-0 total-text">Total: $ {{ parseFloat(cartTotal()).toFixed(2) }}</p>
                      <v-divider class="mt-1"></v-divider>
                    </v-col>
                    <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
                    <v-col cols="12" md="6" class="pl-md-5">
                      <v-text-field
                        placeholder="Name"
                        v-model="editedItem.customer_name"
                        :rules="nameRules"
                        required
                        outlined
                        dense
                        color="primary"
                        class="mb-4"
                        hide-details="auto"
                        :disabled="!allowEditing"
                      ></v-text-field>
                      <v-text-field
                        placeholder="Phone number"
                        v-model="editedItem.customer_number"
                        :rules="numberRules"
                        required
                        outlined
                        dense
                        color="primary"
                        class="mb-4"
                        hide-details="auto"
                        :disabled="!allowEditing"
                      ></v-text-field>
                      <v-menu
                        v-model="dateDialog"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        v-if="editedItem.booking != null"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="formattedDateAndTime"
                            placeholder="Delivery slot"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="dateRules"
                            required
                            outlined
                            dense
                            @click="fetchSlots()"
                            color="primary"
                            class="mb-4"
                            hide-details="auto"
                            :disabled="!allowEditing"
                          ></v-text-field>
                        </template>
                        <v-stepper v-model="stepper" color="primary" min-width="290">
                          <v-stepper-items>
                            <v-stepper-content step="1" class="pa-0">
                              <v-date-picker 
                                v-model="editedItem.booking.slot.slot_date"
                                header-color="primary"
                                color="secondary"
                                :allowed-dates="getAllowedDates"
                                reactive
                                :picker-date.sync="pickerDate"
                                @click:month="fetchSlots()"
                                @change="fetchSlots()"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="dateDialog = false, editedItem.booking.slot.slot_date=''"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="getTimeSlots(formattedDate)"
                                  :disabled="editedItem.booking.slot.slot_date=='' || slotsLoading"
                                  :loading="slotsLoading"
                                >
                                  Pick Slot
                                  <v-icon
                                  >
                                    $next
                                  </v-icon>
                                </v-btn>
                              </v-date-picker>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="pa-0">
                              <v-card>
                                <v-card-title class="primary text-h4 white--text pa-6">
                                  {{ formattedDate }}
                                </v-card-title>
                                <v-card-text class="pa-6 overflow-y-auto card-height">
                                  <v-radio-group v-model="radioGroup">
                                    <v-radio
                                      v-for="slot in timeSlots"
                                      :key="slot.id"
                                      class="font-weight-bold radio-item rounded pa-3"
                                      active-class="selected-item"
                                      :value="slot"
                                      :disabled="slot.is_full"
                                    >
                                      <template v-slot:label>
                                        <v-row class="pa-0 ma-0 black--text">
                                          <v-col cols="3" class="pa-0" align="end">
                                            <span>{{ slot.slot_time.split('-')[0] }}</span> 
                                          </v-col>
                                          <v-col cols="1" class="pa-0 mx-1" align="end">
                                            <span>-</span> 
                                          </v-col>
                                          <v-col cols="3" class="pa-0" align="end">
                                            <span>{{ slot.slot_time.split('-').pop() }}</span>
                                          </v-col>
                                          <v-col align="end" class="pa-0">
                                            <span :class="slot.is_full ? 'red--text': 'green--text'">{{ slot.booking_count }} / {{ slot.capacity }}</span>
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="radioGroup=null, stepper=1"
                                  >
                                    <v-icon>
                                      $prev
                                    </v-icon>
                                    Pick Date
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="dateDialog = false, stepper=1"
                                    :disabled="radioGroup===null"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-card>        
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                      </v-menu>
                      <v-select
                        placeholder="Delivery method"
                        v-model="editedItem.delivery_method"
                        :items="deliveryItems"
                        :rules="deliveryRules"
                        required
                        outlined
                        dense
                        class="mb-4"
                        color="primary"
                        hide-details="auto"
                        :disabled="!allowEditing"
                      ></v-select>
                      <v-textarea
                        v-if="editedItem.delivery_method == 'Delivery'"
                        placeholder="Delivery address"
                        v-model="editedItem.delivery_address"
                        :rules="addressRules"
                        required
                        outlined
                        dense
                        color="primary"
                        class="mb-4"
                        rows="3"
                        auto-grow
                        hide-details="auto"
                        :disabled="!allowEditing || editedItem.delivery_method == 'Self pickup'"
                      ></v-textarea>
                      <v-select
                        placeholder="Payment method"
                        v-model="editedItem.payment_method"
                        :items="paymentItems"
                        :rules="paymentRules"
                        required
                        outlined
                        dense
                        class="mb-4"
                        color="primary"
                        hide-details="auto"
                        :disabled="!allowEditing"
                      ></v-select>
                      <v-textarea
                        placeholder="Special requests"
                        v-model="editedItem.notes"
                        outlined
                        dense
                        color="primary"
                        class="mb-4"
                        rows="3"
                        auto-grow
                        :disabled="!allowEditing"
                      >
                      </v-textarea>
                      <v-btn 
                        depressed
                        class="mb-5 primary-border"
                        block
                        @click="cancelOrder"
                      >
                        Cancel
                      </v-btn>
                      <v-btn 
                        :loading="loading"
                        depressed
                        color="primary"
                        block
                        type="submit"
                        :disabled="!valid || (cartItems.length === 0) || !allowEditing"
                      >
                        {{ create ? 'Place Order': 'Update Order'}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="messageDialog"
          :width="$vuetify.breakpoint.xs?'90%':'60%'"
          persistent
        >
          <v-card>
            <v-container class="pa-0">
              <v-card-title>
                {{ messageTitle }}
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid2">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="prefilledText"
                        label="Confirmation message"
                        outlined
                        :rules="messageRules"
                        required
                        rows="3"
                        auto-grow
                        color="primary"
                      >
                      </v-textarea>
                      <v-btn
                        block
                        depressed
                        color="green"
                        class="mb-4 white--text"
                        :disabled="!valid2"
                        :href="whatsappLink"
                        target="_blank"
                      >
                        <v-icon left>
                          mdi-whatsapp
                        </v-icon>
                        Whatsapp
                      </v-btn>
                      <v-btn 
                        block 
                        @click="messageDialog=false, prefilledText='', messageTitle='', whatsappNumber=''"
                        depressed
                      >
                        Close
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="confirmationDialog"
          :width="$vuetify.breakpoint.xs?'90%':'40%'"
          persistent
        >
          <v-card>
            <v-container class="pa-0">
              <v-card-title>
                {{ confirmationTitle }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-btn
                      depressed
                      block
                      color="green"
                      class="white--text"
                      :loading="confirmationLoading"
                      @click="approval ? approveOrder(confirmationOrder.id) : rejectOrder(confirmationOrder.id)"
                    >
                      Yes
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      depressed
                      block
                      class="primary-border"
                      @click="closeConfirmation()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchOrders(pagination.current_page)"
          class="float-right"
          color="primary"
        >
        </v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from '@/services/api.service.js'

export default {
  data () {
    return {
      adminProfile: {},
      orders: [],
      menus: [],
      cartItems: [],
      deletedItems: [],
      slots: [],
      slotDates: [],
      timeSlots: [],
      radioGroup: null,
      stepper: 1,
      query: '',
      status: null,
      dateRangeText: '',
      dateRange: null,
      start: '',
      end: '',
      valid: false,
      valid2: false,
      loading: false,
      slotsLoading: false,
      confirmationLoading: false,
      selectedItem: null,
      name: '',
      number: '',
      date: '',
      pickerDate: new Date().toISOString().split('T')[0].substring(0,7),
      deliveryItems: [ 'Delivery', 'Self pickup' ],
      paymentItems: [ 'BIBD transfer', 'Cash' ],
      address: '',
      notes: '',
      prefilledText: '',
      whatsappNumber: '',
      messageDialog: false,
      messageTitle: '',
      confirmationDialog: false,
      approval: null,
      confirmationOrder: {},
      orderDialog: false,
      dateDialog: false,
      dateRangeDialog: false,
      item: {},
      create: false,
      allowEditing: false,
      defaultItem: {
        id: '',
        customer_name: '',
        customer_number: '',
        delivery_method: null,
        delivery_address: '',
        payment_method: null,
        booking: { 
          slot: {
            slot_date: ''
          } 
        },
        order_status: { name: '' },
        notes: '',
        order_items: []
      },
      editedItem: {
        id: '',
        customer_name: '',
        customer_number: '',
        delivery_method: null,
        delivery_address: '',
        payment_method: null,
        booking: { 
          slot: {
            slot_date: ''
          } 
        },
        order_status: { name: '' },
        notes: '',
        order_items: []
      },
      mobileHeaders: [
        { text: 'Order ID', value: 'order_number', sortable: false },
        { text: 'Name', value: 'customer_name', sortable: false },
        { text: 'Phone No.', value: 'customer_number', sortable: false },
        { text: 'Status', value: 'order_status', width: '20%', sortable: false }
      ],
      statuses: {
        'new': 1,
        'confirmed': 2,
        'cancelled': 3
      },
      pagination: {
        current_page: 0, 
        per: 0,
        total_count: 0,
        total_page: 0
      },
      nameRules: [
        value => !!value || 'Name is required.'
      ],
      deliveryRules: [
        value => !!value || 'Delivery method is required.'
      ],
      addressRules: [
        value => !!value || 'Address is required.'
      ],
      dateRules: [
        value => !!value || 'Date is required.',
      ],
      paymentRules: [
        value => !!value || 'Payment method is required.'
      ],
      numberRules: [
        value => !!value || 'Phone number is required.',
        value => !!(/^\d{7}$/.test(value)) || 'Phone number must be valid.',
      ],
      messageRules: [
        value => !!value || 'Message is required.',
      ],
    }
  },
  methods: {
    async fetchOrders(page = null) {
      try {
        const res = await apiService.get(`api/v1/admin/orders?page=${page}`, {
          query: this.query,
          status: this.statuses[this.status],
          start_date: this.start,
          end_date: this.end
        })
        const response = res.data
        this.updateNew(response.data.pop()['new'])
        this.orders = response.data
        if (!page) this.pagination = response.pagination
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the orders at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchMenu() {
      try {
        const res = await apiService.get('api/v1/menus')
        const response = res.data
        this.menus = response.data.map(menu => ({ ...menu, qty: 1 }))
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the menu at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchSlots() {
      if (this.editedItem.booking.slot.slot_date != '') this.slotsLoading = true
      this.slots = []
      try {
        const res = await apiService.get('api/v1/admin/slots', { 
          year: new Date(this.pickerDate).getFullYear(),
          month: new Date(this.pickerDate).getMonth()+1
        })
        this.slotsLoading = false
        const response = res.data
        this.slots = response.data
        this.slotDates = [...new Set(this.slots.map(slot => slot.slot_date))]
      }
      catch(error) {
        this.slotsLoading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the slots at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async createOrder() {
      this.loading = true
      const items = this.cartItems.map(({ id, qty, price, amount }) => ({
        menu_id: id,
        quantity: qty,
        unit_price: price,
        amount: amount
      }));
      let orders = {
        order_date: this.editedItem.booking.slot.slot_date,
        order_status_id: 1,
        slot_id: this.radioGroup.id,
        customer_name: this.editedItem.customer_name,
        customer_number: this.editedItem.customer_number,
        delivery_method: this.editedItem.delivery_method,
        delivery_address: this.editedItem.delivery_address,
        payment_method: this.editedItem.payment_method,
        notes: this.editedItem.notes,
        discount: "0",
        total: this.cartTotal(),
        order_items_attributes: items
      }
      try {
        const response = await apiService.create('api/v1/admin/orders', { orders })
        this.loading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: response.data.message || 'Successfully created an order!', 
            status: 'success' 
          })
        this.cancelOrder()
        this.fetchOrders()
      }
      catch(error) {
        this.loading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue creating the order. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async approveOrder(id) {
      this.confirmationLoading = true
      let orders = { order_status_id: 2 }
      try {
        const res = await apiService.update(`api/v1/admin/orders/${id}`, { orders })
        this.confirmationLoading = false
        const response = res.data
        this.closeConfirmation()
        this.messageTitle = 'Order approved.'
        if (response.data.delivery_method == 'Self pickup') {
          this.prefilledText = `Hey ${response.data.customer_name}, Woohoo! Your Order ${response.data.order_number} has been confirmed! Be ready to grab it on ${new Date(response.data.booking.slot.slot_date).toLocaleDateString()} between ${response.data.booking.slot.slot_time}. Pan(see)t you soon!`
        } else {
          this.prefilledText = `Hey there, ${response.data.customer_name}! Your Order ${response.data.order_number} has been locked in! Get excited for your Pancit, set to arrive on ${new Date(response.data.booking.slot.slot_date).toLocaleDateString()} between ${response.data.booking.slot.slot_time.split(' ')[0]} and ${response.data.booking.slot.slot_time.split('-')[1].trim()}. Pan(see)t you soon!`
        }
        this.whatsappNumber = response.data.customer_number
        this.messageDialog = true
        this.fetchOrders(this.pagination.current_page)
        this.$store.dispatch('showSnackbar',
          {
            content: response.data.message || 'Successfully approved the order',
            status: 'success'
          })
      }
      catch(error) {
        this.confirmationLoading = false
        this.$store.dispatch('showSnackbar',
          {
            content: error.response.data.message || 'There was an issue approving the order. Please try again at a later time',
            status: 'error'
          })
      }
    },
    async rejectOrder(id) {
      this.confirmationLoading = true
      let orders = { order_status_id: 3 }
      try {
        const res = await apiService.update(`api/v1/admin/orders/${id}`, { orders })
        this.confirmationLoading = false
        const response = res.data
        this.closeConfirmation()
        this.messageTitle = 'Order cancelled.'
        this.prefilledText = `Hello ${response.data.customer_name}, we regret to inform you that your Order ${response.data.order_number} has been cancelled. Feel free to contact us if you have any inquiries. Thank you.`
        this.whatsappNumber = response.data.customer_number
        this.messageDialog = true
        this.fetchOrders(this.pagination.current_page)
        this.$store.dispatch('showSnackbar',
          {
            content: response.data.message || 'Successfully cancelled the order',
            status: 'success'
          })
      }
      catch(error) {
        this.$store.dispatch('showSnackbar',
          {
            content: error.response.data.message || 'There was an issue cancelling the order. Please try again at a later time',
            status: 'error'
          })
      }
    },
    async updateOrder(id) {
      this.loading = true
      let items = this.cartItems.map(({ id, qty, price, amount, item_id }) => ({
        id: item_id,
        menu_id: id,
        quantity: qty,
        unit_price: price,
        amount: amount
      }))
      if (this.deletedItems.length) {
        items = items.concat(this.deletedItems.map(({ id }) => ({
          id: id,
          _destroy: 1
        })))
      }
      let orders = {
        customer_name: this.editedItem.customer_name,
        customer_number: this.editedItem.customer_number,
        delivery_method: this.editedItem.delivery_method,
        delivery_address: this.editedItem.delivery_address,
        payment_method: this.editedItem.payment_method,
        slot_id: this.radioGroup.id,
        order_date: this.editedItem.booking.slot.slot_date,
        notes: this.editedItem.notes,
        order_items_attributes: items
      }
      try {
        const res = await apiService.update(`api/v1/admin/orders/${id}`, { orders })
        this.loading = false 
        const response = res.data
        if (this.editedItem.booking.slot.id !== response.data.booking.slot.id) {
          this.messageTitle = 'Order date updated.'
          if (response.data.delivery_method == 'Self pickup') {
            this.prefilledText = `Hello ${response.data.customer_name}, your Order ${response.data.order_number} pickup has been changed to ${new Date(response.data.booking.slot.slot_date).toLocaleDateString()} at ${response.data.booking.slot.slot_time}. We will send a confirmation again once it is approved. Thank you for your cooperation.`
          } else {
            this.prefilledText = `Hello ${response.data.customer_name}, your Order ${response.data.order_number} delivery has been changed to ${new Date(response.data.booking.slot.slot_date).toLocaleDateString()} at ${response.data.booking.slot.slot_time}. We will send a confirmation again once it is approved. Thank you for your cooperation.`
          }
          this.whatsappNumber= response.data.customer_number
          this.messageDialog = true
        }
        this.cancelOrder()
        this.fetchOrders(this.pagination.current_page)
        this.$store.dispatch('showSnackbar', 
          { 
            content: response.data.message || `Successfully updated the order`, 
            status: 'success' 
          })
      }
      catch(error) {
        this.loading = false 
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue placing the order. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async updateOrderDialog(id) {
      try {
        const res = await apiService.get(`api/v1/admin/orders/${id}`)
        const response = res.data
        this.item = response.data
        if (this.item.order_status.name === 'new' && this.adminProfile.role_id != 3) {
          this.allowEditing = true
        }
        if (this.item.booking != null) {
          this.editedItem.booking.slot.slot_date = this.item.booking.slot.slot_date
          this.radioGroup = this.item.booking.slot
        }
        for (let i=0; i < this.item.order_items.length; i++) {
          let menu = this.menus.find(menu => menu.name  == this.item.order_items[i].menu.name)
          menu.qty = this.item.order_items[i].quantity
          menu.amount = menu.price * menu.qty
          let menu2 = { ...menu, item_id: this.item.order_items[i].id }
          this.cartItems.push(menu2)
          menu.qty = 1
        }
        this.create = false
        this.editedItem = Object.assign({}, this.item)
        this.orderDialog = true
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the order at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    closeConfirmation() {
      this.confirmationDialog = false
      this.confirmationOrder = {}
      this.approval = null
    },
    confirmApproveOrder(order) {
      this.confirmationDialog = true
      this.confirmationOrder= order
      this.approval = true
    },
    confirmRejectOrder(order) {
      this.confirmationDialog = true
      this.confirmationOrder = order
      this.approval = false
    },
    getTimeSlots(day) {
      this.timeSlots = this.slots.filter((o) => o.slot_date === day)
      this.stepper = 2
    },
    createOrderDialog() {
      this.create = true
      this.allowEditing = true
      this.orderDialog = true
    },
    cancelOrder() {
      this.orderDialog=false
      this.create = false 
      this.radioGroup = null
      this.pickerDate = new Date().toISOString().split('T')[0].substring(0,7)
      this.allowEditing = false
      this.removeCart()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.booking.slot.slot_date = ''
      })
      this.$refs.orderForm.reset()
      this.$refs.orderForm.resetValidation()
    },
    clearDate() {
      this.dateRange = null
      this.dateRangeText = ''
      this.start = ''
      this.end = ''
      this.dateRangeDialog = false
      this.fetchOrders()
    },
    updateDate() {
      if (this.dateRange === null) {
        this.dateRangeDialog = false
        return
      }
      switch(this.dateRange.length) {
        case 1:
          this.dateRangeText = new Date(this.dateRange[0]).toLocaleDateString()
          this.start = new Date(new Date(this.dateRange[0]).setHours(0, 0, 0))
          this.end = new Date(new Date(this.dateRange[0]).setHours(23, 59, 59))
          break
        case 2:
          if (new Date(this.dateRange[0]) > new Date(this.dateRange[1])) {
            [this.dateRange[0], this.dateRange[1]] = [this.dateRange[1], this.dateRange[0]]
          }
          this.dateRangeText = new Date(this.dateRange[0]).toLocaleDateString() + ' - ' + new Date(this.dateRange[1]).toLocaleDateString()
          this.start = new Date(new Date(this.dateRange[0]).setHours(0, 0, 0))
          this.end = new Date(new Date(this.dateRange[1]).setHours(23, 59, 59))
          break
      }
      this.dateRangeDialog = false
      this.fetchOrders()
    },
    getAllowedDates(val) {
      const [year, month, day] = val.split('-')
      const date = `${day}/${month}/${year}`
      if (this.slotDates.indexOf(date) !== -1) {
        return true
      } else return false
    },
    getColor(status) {
      if (status === 'new') return 'accent'
      else if (status === 'cancelled') return 'red'
      else return 'green'
    },
    addToCart(id) {
      this.$nextTick(() => {
        this.selectedItem=null  
      })
      let menu = this.menus.find(menu => menu.id  == id)
      menu.amount = menu.price * menu.qty
      this.menus = this.menus.map(menu => ({ ...menu, qty: 1 }))
      if (this.cartItems.find(o => o.id ===  menu.id)) {
        this.cartItems.find(o => o.id ===  menu.id).qty += menu.qty
        this.cartItems.find(o => o.id ===  menu.id).amount += menu.amount
      }
      else {
        this.cartItems.push(menu)
      }
    },
    updateCart(item) {
      this.cartItems.find(o => o.id ===  item.id).qty = item.qty
      this.cartItems.find(o => o.id ===  item.id).amount = item.price * item.qty
    },
    removeFromCart(id) {
      const index = this.cartItems.findIndex(o => o.id === id)

      if (index > -1) {
        if (this.cartItems[index].item_id) {
          this.deletedItems.push(
            { id: this.cartItems[index].item_id }
          )
        }
        this.cartItems.splice(index, 1)
      }
    },
    removeCart() {
      this.cartItems = []
      this.deletedItems = []
    },
    cartTotal() {
      const total = this.cartItems.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.amount);
      }, 0);
      return total
    },
    updateNew(val) {
      this.$store.dispatch('updateNew', {product: val})
    },
    notesExist(item) {
      if (![null, ''].includes(item.notes)) return true
    },
    onSubmit(id) {
      this.create ? this.createOrder() : this.updateOrder(id)
    },
    getAdminProfile() {
      this.adminProfile = JSON.parse(localStorage.getItem('adminAuth'))
    },
  },
  mounted () {
    this.getAdminProfile()
  },
  created () {
    this.fetchOrders()
    this.fetchMenu()
  },
  computed : {
    formattedDate() {
      if (!this.editedItem.booking.slot.slot_date) return null
      const [year, month, day] = this.editedItem.booking.slot.slot_date.split('-')

      return `${day}/${month}/${year}`
    },
    formattedDateAndTime() {
      if (!this.editedItem.booking.slot.slot_date || !this.radioGroup) return null
      const [year, month, day] = this.editedItem.booking.slot.slot_date.split('-')
      return `${day}/${month}/${year}  (${this.radioGroup.slot_time})`
    },
    whatsappLink() {
      return `https://wa.me/673${this.whatsappNumber}?text=${encodeURIComponent(this.prefilledText)}`
    },
    confirmationTitle() {
      if (this.approval) return `Approve order ${this.confirmationOrder.order_number} ?`
      return `Reject order ${this.confirmationOrder.order_number} ?`
    },
    getHeaders() {
      if (this.$vuetify.breakpoint.sm) return this.mobileHeaders
      let xsHeaders = this.mobileHeaders.filter((val, index, array) => {
        return index === 0 || index === array.length - 1
      })
      return xsHeaders
    }
  },
  watch: {
    pickerDate() {
      if (this.dateDialog) {
        this.fetchSlots()
      }
    }
  }
}
</script>

<style lang="scss">
  .mobile-table > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: #8C9B5A;
    color: white !important;
  }
  .chips {
    width: 100%;
  }
  .radio-item {
    background-color: #fafafa;
  }
  .v-radio--is-disabled {
    opacity: 0.5;
  }
  .selected-item {
    border: 1px solid #A78FB3;
  }
  .primary-border {
    border: 1px solid #8C9B5A;
  }
  .total-text {
    font-size: 20px;
    padding: 10px 8px 10px 0;
  }
  .card-height {
    height: 290px;
  }
  .col-width {
    width: 295px;
  }
</style>
